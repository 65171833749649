import React, { useCallback, useContext, useMemo, useState } from 'react'

import { getTranslate } from 'react-localize-redux'
import { getUser } from '../../../../reducers/UserReducer'
import { connect } from 'react-redux'
import SectorsTable from './SectorsTable'
import useRequest from '../../../../hooks/useRequest'
import { TableParameters } from '../../../../utils/entities/tableParameters'
import { InstitutionContext } from '../../../../Providers/InstitutionProvider'
import { getInstitutionSectors } from '../../../../utils/api/institution'
import { archiveSector, deleteSector } from '../../../../utils/api/sector'
import { ORDER_BY, ROLE_NURSE } from '../../../../utils/constants'
import SectorModal from '../../../../Providers/Forms/SectorModal'
import FilesManager from '../../../../Components/shared/FilesManager'
import SectorSectionUsers from '../../../../Components/shared/SectorSectionUsers'

export const ACTIONS = {
  MANAGE_FILES: 'MANAGE_FILES',
  DISPLAY_AFFILIATED_USERS: 'DISPLAY_AFFILIATED_USERS'
}

const DEFAULT_STATE = { data: null, action: null }

const mapStateToProps = state => ({ t: getTranslate(state.locale), user: getUser(state.getUser) })

const SectorsListView = ({ user, displayManagePresets, t }) => {
  const { actTypes, institution, users } = useContext(InstitutionContext)

  const [parameters, setParameters] = useState(new TableParameters({ pageSize: 50, orderBy: ORDER_BY.NAME }))
  const [selectedSector, setSelectedSector] = useState(DEFAULT_STATE)
  const { data, loading, metadata, reload } = useRequest({
    request: getInstitutionSectors,
    parameters: useMemo(() => [user, institution, { ...parameters, archived: false, contexts: ['document'] }], [user?.id, institution, parameters])
  })

  const globalActTypes = useMemo(() => actTypes.filter(a => a.school === null), [actTypes])
  const isNurse = useMemo(() => user.roles.includes(ROLE_NURSE), [user.roles])

  const handleArchive = useCallback((sector, status) => {
    archiveSector(user, sector, status).then(reload)
  }, [user, reload])

  const handleDelete = useCallback(sector => deleteSector(user, sector), [user])

  const sectors = useMemo(() => data.map(s => ({ ...s, institution })), [institution, data])

  const resetState = useCallback(() => setSelectedSector(DEFAULT_STATE), [setSelectedSector])

  return (
    <>
      <SectorModal actTypes={globalActTypes} users={users}>
        <SectorsTable
          data={sectors}
          disabledAdd={isNurse}
          institution={institution}
          loading={loading}
          metadata={metadata}
          parameters={parameters}
          displayManagePresets={displayManagePresets}
          onArchive={s => handleArchive(s, true)}
          onAction={setSelectedSector}
          onDelete={handleDelete}
          onParametersChange={setParameters}
          onUnarchive={s => handleArchive(s, false)}
          onReload={reload}
        />
      </SectorModal>
      <FilesManager
        entity={selectedSector.action === ACTIONS.MANAGE_FILES ? selectedSector.data : null}
        entityName='Sector'
        onClose={resetState}
      />
      {selectedSector.action === ACTIONS.DISPLAY_AFFILIATED_USERS && (
        <SectorSectionUsers
          entity={selectedSector.data}
          onClose={resetState}
        />
      )}
    </>
  )
}

export default connect(mapStateToProps)(SectorsListView)
