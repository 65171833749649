import { isObject, validateString } from '..'
import { VALIDATION_FIELDS } from '../validators'

export const HOSPITAL_DEPARTMENT_VALIDATORS = [
  { key: VALIDATION_FIELDS.NAME, validator: h => validateString(h.name, { notEmpty: true }) },
  { key: VALIDATION_FIELDS.COLOR, validator: h => validateString(h.color, { nullable: true }) }
]

export const getName = h => {
  return h.name ?? null
}

export const getManagerLabel = h => {
  let label = ''

  if (isObject(h.manager)) {
    if (h.manager.firstname && h.manager.lastname) {
      label = `${h.manager.firstname} ${h.manager.lastname} - `
    }

    label += h.manager.email
  }

  return label
}

export const getColor = h => {
  return h.color ?? null
}

export class HospitalDepartment {
  constructor ({ id, institution = null, name = '', manager = null, color = '' } = {}) {
    this.id = id ?? -1
    this.institution = institution
    this.name = name
    this.manager = isObject(manager) ? manager : null
    this.color = color
  }

  toRequestBody = () => {
    const body = { ...this }

    body.institution = this.institution?.id ?? null
    body.manager = this.manager?.id ?? null

    return body
  }
}
