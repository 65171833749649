const INTERNEO_FAQ_URL_PART_1 = 'https://support.opalsolutions.be/hc/'
const INTERNEO_FAQ_URL_PART_2 = '/categories/360003066859-Comment-utiliser-Interneo-'
export const LANGUAGE_TAG_EN = 'en'
export const LANGUAGE_TAG_FR = 'fr'
export const LANGUAGE_TAG_NL = 'nl'
export const LANGUAGE_LOCALE_TAG_FR_BE = 'fr_be'
export const LANGUAGE_LOCALE_TAG_FR_FR = 'fr_fr'
export const LANGUAGE_LOCALE_TAG_EN = 'en'
export const LANGUAGE_LOCALE_TAG_NL = 'nl'

export const EXPORT_TYPE = {
  CSV: 'csv',
  XLSX: 'xlsx'
}

// GLOBAL CONFIGURATION PART
export const SUPPORTED_LANGUAGES = [LANGUAGE_TAG_EN, LANGUAGE_TAG_FR, LANGUAGE_TAG_NL]
export const SUPPORTED_LANGUAGE_LOCALES = [LANGUAGE_LOCALE_TAG_EN, LANGUAGE_LOCALE_TAG_FR_BE, LANGUAGE_LOCALE_TAG_FR_FR, LANGUAGE_LOCALE_TAG_NL]
export const SUCCESS_API_REQUEST_STATE = 'success'

/** Part of the constant related to user roles */
export const STANDARD_DRAWER_WIDTH = '550px'

/** ********************* DIMENSIONS PART ********************************************/

export const XS = 768 // px very small => android
export const SM = 992 // px small device => tablet
export const MD = 1200 // px

/** ********************* END OF DIMENSIONS PART ********************************************/

/** ******************** USER ROLES PART ***********************/
// super admin role
export const ROLE_ADMIN = 'ROLE_ADMIN'

// roles for users in the hospital side
export const ROLE_INSTITUTION_GROUP_ADMIN = 'ROLE_INSTITUTION_GROUP_ADMIN'
export const ROLE_HOSPITAL_ADMIN = 'ROLE_HOSPITAL_ADMIN'
export const ROLE_NURSE = 'ROLE_NURSE'
export const INSTITUTION_ROLES = [
  ROLE_INSTITUTION_GROUP_ADMIN,
  ROLE_HOSPITAL_ADMIN,
  ROLE_NURSE
]

// roles for users in the school side
export const ROLE_ENCODER = 'ROLE_ENCODER'
export const ROLE_COORDINATOR = 'ROLE_COORDINATOR'
export const ROLE_SUPERVISOR = 'ROLE_SUPERVISOR'
export const ROLE_VALIDATOR = 'ROLE_VALIDATOR'
export const ROLE_UNKNOW = 'ROLE_UNKNOW'

export const SCHOOL_ROLES = [
  ROLE_ENCODER,
  ROLE_COORDINATOR,
  ROLE_SUPERVISOR,
  ROLE_VALIDATOR
]

export const ROLE_STUDENT = 'ROLE_STUDENT'

export const SUPPORTED_ROLES = [
  ROLE_ADMIN, ROLE_INSTITUTION_GROUP_ADMIN, ROLE_HOSPITAL_ADMIN, ROLE_NURSE, ROLE_ENCODER, ROLE_COORDINATOR, ROLE_SUPERVISOR, ROLE_VALIDATOR,
  ROLE_STUDENT
]

/** ****************** END OF USER ROLES PART ******************/

// CONTEXT PART
export const ADMIN_CONTEXT = 'ADMIN'
export const SCHOOL_CONTEXT = 'SCHOOL'
export const INSTITUTION_CONTEXT = 'HOSPITAL'
export const STUDENT_CONTEXT = 'STUDENT'
export const NONE_CONTEXT = 'NONE'

// INSTITUTION OPTIONS PART
export const INSTITUTION_OPTIONS = {
  DISPLAY_QUOTA_PER_SECTOR: 'display_quotas_per_sector',
  MODULE_SETTINGS: 'module_settings',
  DISPLAY_INTERNSHIPS_WITHOUT_STUDENT: 'display_internships_without_student'
}
export const INSTITUTION_OPTION_PUBLIPOSTAGE = 'publipostage'

// ORDER BY PART
export const ORDER_BY = {
  ACT_TYPE: 'act-type',
  ADDRESS: 'address',
  BIRTHDATE: 'birthdate',
  CATEGORY: 'category',
  CITY: 'city',
  COMMENT: 'comment',
  COUNTRY: 'country',
  CREATED_AT: 'created-at',
  DESCRIPTION: 'description',
  EMAIL: 'email',
  EMPLACEMENT: 'emplacement',
  END_DATE: 'end-date',
  FIRSTNAME: 'firstname',
  FIRST_NAME: 'first-name',
  HOSPITAL_DEPARTMENT: 'hospital-department',
  INSTITUTION: 'institution',
  LANGUAGE: 'language',
  LASTNAME: 'lastname',
  LAST_NAME: 'last-name',
  LAST_CONNECTION: 'last-login',
  MANAGER: 'manager',
  NAME: 'name',
  ORIGINAL_NAME: 'original-name',
  PHONE_NUMBER: 'phone-number',
  ZIPCODE: 'zipcode',
  REGISTRATION: 'registration-number',
  REQUEST_ORIGIN: 'request-origin',
  ROLE: 'role',
  SCHOOL: 'school',
  SCHOOL_YEAR: 'school-year',
  SCHOOL_YEAR_NAME: 'school-year-name',
  SECTION: 'section',
  SECTOR: 'sector',
  STATE: 'state',
  START_DATE: 'start-date',
  TYPE: 'type',
  USERNAME: 'username',
  COORDINATOR: 'coordinator'
}

export const ORDER_BY_CREATED_AT = 'created-at'
export const ORDER_BY_INSTITUTION = 'institution'
export const ORDER_BY_SCHOOL = 'school'
export const ORDER_BY_START_DATE = 'start-date'
export const ORDER_BY_SECTION = 'section'
export const ORDER_BY_SECTOR = 'sector'
export const ORDER_BY_STUDENT = 'student'
export const SEARCH_BADGE = 'search-badge'

export const DIRECTION_ASC = 'asc'
export const DIRECTION_DESC = 'desc'

export const MODES = {
  INTERNEO: 'INTERNEO',
  WIDGET: 'WIDGET'
}

const LIGHT_TEXT_COLOR = 'white'
const DARK_TEXT_COLOR = 'black'

// institution options available values
const RECRUITMENT_MODULE = 'recruitment_module'
const NURSE_PRESET_MANAGEMENT = 'nurse_preset_management'
const QUOTAS_PER_SECTOR = 'display_quotas_per_sector'
const RECRUITEMENT_GREEN_MATCH_EMAILING = 'recruitment_green_match_emailing'
const PUBLIPOSTAGE = 'publipostage'
export const QUOTA_PER_PAUSE = 'quota_per_pause'
const AUTO_ACCEPTATION_DAYS = 'auto-acceptation-days'
export const DRAFT_INTERNSHIPS = 'draft_internships'
export const MODULE_SETTINGS = 'module_settings'
export const INTERNSHIPS_AUTO_ACCEPTATION = 'internships_auto_acceptation'
export const SCHEDULE_EDITION_BY_SCHOOLS = 'schedule_edition_by_schools'
export const STUDENT_DESIDERATA_LIMIT = 'student_desiderata_limit'
export const NURSE_INTERNSHIP_ACCCEPTANCE = 'nurse_internship_acceptance'
export const BLOCK_SCHEDULE_CODE_EDITION = 'block_schedule_code_edition'
export const UPDATE_GLOBAL_QUOTA = 'update_global_quota'

/* List of all schools options */
export const SUPERVISORS_EDIT_SCHEDULE = 'supervisors_edit_schedule'
export const CUSTOM_LOGO = 'custom_logo'
export const ACT_HOURS_VERIFICATION = 'act_hours_verification'
export const INTERNSHIPS_CARPOOLING = 'internships_carpooling'
export const DRAFT_INTERNSHIPS_TYPE = 'draft_internships'
export const TIVOLI_CUSTOM_EXPORT = 'tivoli_custom_export'

export const GENDER_OPTIONS = props => {
  return ([
    { id: 'M', name: props.t('M') },
    { id: 'F', name: props.t('F') },
    { id: 'Other', name: props.t('Other') }
  ])
}

export const COUNTRIES = props => {
  return ([
    { id: 'BE', name: props.t('Belgium') },
    { id: 'FR', name: props.t('France') },
    { id: 'LU', name: props.t('Luxembourg') },
    { id: 'NL', name: props.t('Netherlands') },
    { id: 'CH', name: props.t('Switzerland') },
    { id: 'Other', name: props.t('Other') }
  ])
}

const INSTITUTION_CONSTRAINT_TYPES = {
  INTERNSHIPS_BLOCKING: 'INTERNSHIPS_BLOCKING'
}
const ENTITY_TYPES = {
  STUDENT: 'Student'
}

export const internshipStates = {
  PRE_ACCEPTED: -3,
  PENDING_STUDENT_DATA_MISSING: -2,
  PENDING_NO_SECTOR: -1,
  CREATED: 0,
  PENDING: 1,
  ACCEPTED: 3,
  AUTO_ACCEPTED: 4,
  REFUSED: 5,
  CANCELED: 6,
  ACCEPTED_UNMANAGED: 7,
  SCHEDULE_ADDED: 8,
  SCHEDULE_VALIDATED: 9,
  SCHEDULE_TO_VALIDATE: 10
}

export const internshipStatesString = {
  '-3': 'PRE_ACCEPTED',
  '-2': 'PENDING_STUDENT_DATA_MISSING',
  '-1': 'PENDING_NO_SECTOR',
  0: 'CREATED',
  1: 'PENDING',
  3: 'ACCEPTED',
  4: 'AUTO_ACCEPTED',
  5: 'REFUSED',
  6: 'CANCELED',
  7: 'ACCEPTED_UNMANAGED',
  8: 'SCHEDULE_ADDED',
  9: 'SCHEDULE_VALIDATED',
  10: 'SCHEDULE_TO_VALIDATE'
}

export const INTERNSHIP_STATES_STRING = {
  PRE_ACCEPTED: 'pre-accepted',
  ACCEPTED: 'accepted',
  CANCELED: 'canceled',
  PENDING: 'pending',
  REFUSED: 'refused',
  SCHEDULE_ADDED: 'schedule-added',
  TO_VALIDATE: 'to-validate',
  VALIDATED: 'validated',
  WITHOUT_INSTITUTION: 'without-institution'
}

const FULL_DATE_WITHOUT_TIMEZONE = 'YYYY-MM-DD HH:mm:ss'
const DATE_WITHOUT_TIME = 'YYYY-MM-DD'
const EUROPEAN_DATE = 'DD/MM/YYYY'
const SHORT_EUROPEAN_DATE = 'DD/MM'

export const ANTD_FORM_VALIDATE_STATUS = 'success'
export const ANTD_FORM_ERROR_STATUS = 'error'

export const SUCCESS_REQUEST_STATUS = 'success'
export const NULL_SECTION = 'global'

export const DATE_FORMAT_API = 'YYYY-MM-DD'
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
export const URI_FORMAT = 'YYYY-MM-DD'
export const internshipStatesStringToNum = filterState => internshipStates[filterState.replace('INTERNSHIP_STATE_', '')]
export const internshipOrderStringToShort = sortBy => sortBy.replace('SORT_BY_', '')

export {
  FULL_DATE_WITHOUT_TIMEZONE,
  DATE_WITHOUT_TIME,
  EUROPEAN_DATE,
  SHORT_EUROPEAN_DATE,
  INTERNEO_FAQ_URL_PART_1,
  INTERNEO_FAQ_URL_PART_2,
  LIGHT_TEXT_COLOR,
  DARK_TEXT_COLOR,
  RECRUITMENT_MODULE,
  NURSE_PRESET_MANAGEMENT,
  QUOTAS_PER_SECTOR,
  RECRUITEMENT_GREEN_MATCH_EMAILING,
  PUBLIPOSTAGE,
  AUTO_ACCEPTATION_DAYS,
  INSTITUTION_CONSTRAINT_TYPES,
  ENTITY_TYPES
}
